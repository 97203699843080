import React from 'react';
import { useNavigate } from 'react-router-dom';
import MaterialTable from '@material-table/core';
import Button from '@mui/material/Button';
import StatusCell from './StatusCell';
import PatientDetailsCell from './PatientDetailsCell';
import ArrivalCell from './ArrivalCell';
import ExaminationCell, { ExaminationCellFilter } from './ExaminationSiteCell';
import ActionsMenu from './ActionsMenu';
import Recycle from '@a2d24/care-at-work-core/Recycle';
import { sharedStyles } from '../../shared/styles';
import { useStores } from '@a2d24/care-at-work-state/stores';
import useDeadpollList from '@a2d24/care-at-work-core/Deadpoll/useDeadpollList';
import { useSnackbar } from 'notistack';

const ConsultsTable = ({ config, props }) => {
    const navigate = useNavigate();
    const sharedClasses = sharedStyles();
    const { authStore } = useStores();
    const { enqueueSnackbar } = useSnackbar();
    const userRole = authStore.selectedRole.role;
    const isGlobal = props?.awaiting_type === 'global';
    const { data:deadpollConsults, loading:isLoadingConsults } = useDeadpollList(
        `${isGlobal ? authStore.occhealthPracticeClient : authStore.occhealthPracticeId}#${
            props.deadpoll_list_id
        }`
    )
    const consults = deadpollConsults.map((obj) => {
        return JSON.parse(JSON.stringify(obj));
    });
    const examinationSiteLookups = {};
    const examinationSiteNumber = {};
    consults.forEach((consult) => {
        if (consult.dsp){
            if(examinationSiteNumber[consult.dsp_reference]){
                examinationSiteNumber[consult.dsp_reference] += 1;
            }
            else{
                examinationSiteNumber[consult.dsp_reference] = 1;
            }
            examinationSiteLookups[consult.dsp_reference] = `${consult.dsp.name} (${examinationSiteNumber[consult.dsp_reference]})`;
        }
    });

    let columns = [
        {
            field: 'status',
            title: 'STATUS',
            render: (rowData) => <StatusCell consult={rowData} />,
            filtering: false,
        },
        {
            field: 'patient_detail',
            title: 'PATIENT DETAILS',
            customFilterAndSearch: (term, rowData) =>
                `${rowData.patient.first_name} ${rowData.patient.last_name}`
                    .toLowerCase()
                    .includes(term.toLowerCase()),
            render: (rowData) => <PatientDetailsCell consult={rowData} />,
            filtering: false,
        },
        {
            field: 'arrival',
            title: 'ARRIVAL TIME',
            render: (rowData) => <ArrivalCell consult={rowData} />,
            filtering: false,
        },
    ];
    if (isGlobal) {
        columns = [
            ...columns.slice(0, 2),
            {
                field: 'dsp_reference',
                title: 'EXAMINATION SITE',
                render: (rowData) => <ExaminationCell consult={rowData} />,
                filtering: true,
                lookup: examinationSiteLookups,
                filterComponent: (props) =>  <ExaminationCellFilter {...props}/>
            },
            ...columns.slice(2),
        ];
    }

    const handleClick = (consult) => {
        if (consult.consult_status === 'not_started') {
            Recycle.rpcPostV2({
                controller: 'ConsultController',
                method: 'start_consult',
                kwargs: { consult_id: consult.consult_id },
            }).then((res) => {
                navigate(`/consults/${consult.consult_id}`, { state: { consultStartedNow: true } });
            }).catch((err) =>{
                if ([409, 428].includes(err.response?.status)) {
                    enqueueSnackbar(err.response?.data?.message, { variant: 'error' });
                }

            })
        } else {
            navigate(`/consults/${consult.consult_id}`);
        }
    };

    const is_consult_started = (consult) => {
        if (consult.consult_status === 'not_started') {
            return false;
        }
        if (
            consult.consult_status === 'in_progress' &&
            consult.procedures.every((procedure) => {
                return procedure.procedure_status === 'pending';
            })
        ) {
            return false;
        }
        return true;
    };

    return (
        <MaterialTable
            data={consults || []}
            columns={columns}
            title={
                <div className={sharedClasses.subheading}>
                    {config.title || props.title || 'Consults'}
                </div>
            }
            actions={[
                (rowData) => ({
                    label: 'Consult',
                    onClick: handleClick,
                    hidden:
                        ['not_started', 'in_progress', 'procedures_completed'].indexOf(
                            rowData.consult_status
                        ) < 0,
                    showOtherOptions: true,
                }),
                (rowData) => ({
                    label: 'Finalise',
                    onClick: handleClick,
                    hidden: rowData.consult_status !== 'awaiting_omp',
                    showOtherOptions: true,
                }),
            ]}
            components={{
                Action: (props) => {
                    const buttonProps = props.action.action(props.data);
                    const menuOptions = [];
                    if (
                        !is_consult_started(props.data) &&
                        ['clinician', 'dsp_omp'].includes(userRole)
                    ) {
                        menuOptions.push('Delete');
                    }
                    return buttonProps.hidden ? null : (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row-reverse',
                                justifyContent: 'flex-end',
                                marginRight: '15px',
                                width: '100%',
                            }}
                        >
                            {buttonProps.showOtherOptions ? (
                                <ActionsMenu consult={props.data} options={menuOptions} />
                            ) : null}
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => buttonProps.onClick(props.data)}
                            >
                                {buttonProps.label}
                            </Button>
                        </div>
                    );
                },
            }}
            options={{
                headerStyle: {
                    color: '#999999',
                    fontSize: '11px',
                    fontWeight: 600,
                    lineHeight: '15px',
                    paddingRight: '15px',
                },
                filterCellStyle: {
                    paddingBottom: '0px',
                    paddingTop: '0px',
                },
                actionsColumnIndex: -1,
                searchFieldStyle: { marginRight: '48px' },
                filtering: true,
            }}
            isLoading={isLoadingConsults}
        />
    );
};

export default ConsultsTable;
