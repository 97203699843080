import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import makeStyles from '@mui/styles/makeStyles';
import Divider from './Divider';

const DiseaseManagementDetail = ({
    rule: { business_rules_evaluation_result, disease_management_paths },
}) => {
    return (
        disease_management_paths && (
            <div>
                <Typography style={{ textTransform: 'capitalize' }} variant="h6">
                    Disease Management Recommendation
                </Typography>
                <ul>
                    {disease_management_paths.map((d, idx) => (
                        <li key={idx}>
                            <Typography variant="body1">
                                {titleCase(d.disease)}
                                {pathwayText(d.plan_pathway.name)}
                            </Typography>
                        </li>
                    ))}
                </ul>
            </div>
        )
    );
};

const DiseaseManagementResult = ({ props: { rules_result } }) => {
    const classes = useStyles();
    if (!rules_result?.disease_management_paths?.length > 0) return null;
    return (
        <Paper className={classes.root}>
            <DiseaseManagementDetail rule={rules_result} />
        </Paper>
    );
};

const titleCase = (s) =>
    s
        .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
        .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase());

const pathwayText = (pathwayName) => (pathwayName !== 'Default' ? ` - ${pathwayName}` : '');

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
        marginTop: '24px'
    },
}));

export default DiseaseManagementResult;
