import React from 'react';
import MaterialTable from '@material-table/core';
import makeStyles from '@mui/styles/makeStyles';
import ServiceAndDateCell from './ServiceAndDateCell';
import ProviderCell from './ProviderCell';
import Button from '@mui/material/Button';
import PatientHistoryTableDialog from '../PatientHistoryTableDialog';

const PatientHistoryTable = ({ config, props }) => {
    const classes = useStyles();
    const [openConsultDocumentTable, setopenConsultDocumentTable] = React.useState(false);
    const [consult, setConsult] = React.useState(null);
    const columns = [
        {
            field: 'service_and_date',
            title: 'SERVICE & DATE',
            render: (rowData) => <ServiceAndDateCell history={rowData} />,
        },
        {
            field: 'provider',
            title: 'PROVIDER',
            render: (rowData) => <ProviderCell history={rowData} />,
        },
        {
            field: 'actions',
            render: (rowData) => (
                <div className={classes.actionsContainer}>
                    <Button
                        variant="contained"
                        onClick={() => handleopenConsultDocumentTable(rowData)}
                        color="primary"
                    >
                        Records
                    </Button>
                </div>
            ),
        },
    ];

    const handleopenConsultDocumentTable = (rowData) => {
        setConsult(rowData);
        setopenConsultDocumentTable(true);
    };

    const handleCloseConsultDocumentTable = () => {
        setopenConsultDocumentTable(false);
    };

    return (
        <div style={{marginTop: '24px'}}>
            <MaterialTable
                data={props.patient_history || []}
                columns={columns}
                title={config.title ? config.title : 'Patient History'}
                options={{
                    headerStyle: {
                        color: '#999999',
                        fontSize: '11px',
                        fontWeight: 600,
                        lineHeight: '15px',
                    },
                    search: false,
                }}
            />
            <PatientHistoryTableDialog
                open={openConsultDocumentTable}
                consult={consult}
                handleCloseConsultDocumentTable={handleCloseConsultDocumentTable}
            />
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    actionsContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        columnGap: theme.spacing(1),
        width: '224px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

export default PatientHistoryTable;
