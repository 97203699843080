import React from 'react';
import MaterialTable from '@material-table/core';
import makeStyles from '@mui/styles/makeStyles';
import useInterval from '../../hooks/useInterval';
import Chip from '@mui/material/Chip';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Recycle from '@a2d24/care-at-work-core/Recycle';
import DocumentViewerDialog from '../DocumentViewer/DocumentViewerDialog';

import { Button } from '@mui/material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import NotesIcon from '@mui/icons-material/Notes';
import CreateIcon from '@mui/icons-material/Create';
import GenerateDocumentDialog from '../GenerateDocumentDialog';
import TitleTypeDocumentCell from './TitleTypeDocumentCell';
import { getReportDocument } from '../../Apis/Uploads';
import { useSnackbar } from 'notistack';


const ClinicalDocumentsTable = ({ config, props }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar()
    const [tableData, setTableData] = React.useState(props.clinical_documents);
    const [tableLoading, setTableLoading] = React.useState(false);
    const [isTimerRunning, setIsTimerRunning] = React.useState(true);
    const [openClinicalDocument, setOpenClinicalDocument] = React.useState(false);
    const [openPdfViewer, setOpenPdfViewer] = React.useState(false);
    const [filePresignedURL, setFilePresignedURL] = React.useState('');
    const [documentTitle, setDocumentTitle] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [hidden, setHidden] = React.useState(false);
    const [clinicalDocumentType, setClinicalDocumentType] = React.useState('');
    const [waitingPoll, setWaitingPoll] = React.useState(0);
    const waitingMax = 2;

    const handleVisibility = () => {
        setHidden((prevHidden) => !prevHidden);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useInterval(
        () => {
            // Your custom logic here
            Recycle.rpcPostV2(
                {
                    controller: 'ConsultController',
                    method: 'get_clinical_documents_for_consult',
                    kwargs: { consult_id: props.consult_id },
                },
                false
            )
                .then((res) => {
                    setTableData(res);
                    const tempWaitingPoll = waitingPoll +1;
                    setWaitingPoll(tempWaitingPoll);
                    if (tempWaitingPoll >= waitingMax) {
                        if (res.every((v) => v.document_status === 'generated') === true) {
                            setIsTimerRunning(false);
                        } else {
                            setIsTimerRunning(true);
                        }
                    }
                })
                .catch((err) => {});
        },
        isTimerRunning ? 2500 : null
    );

    const handleOpenPDF = () => {
        setOpenPdfViewer(true);
    };

    const setClosePdfViewer = () => {
        setOpenPdfViewer(false);
    };

    const handleCloseReferralNote = () => {
        setOpenClinicalDocument(false);
    };

    const handleSubmitClinicalDocument = (title, documentType, metadata) => {
        setOpenClinicalDocument(false);
        Recycle.rpcPostV2({
            controller: 'ConsultController',
            method: 'generate_clinical_document',
            kwargs: {
                consult_id: props.consult_id,
                title: title,
                document_type: documentType,
                metadata: metadata,
            },
        })
            .then((res) => {
            })
            .catch((err) => {});
        setWaitingPoll(0);
        setIsTimerRunning(true);
    };

    const actions = [
        { icon: <NoteAddIcon />, name: 'Referral Note', type: 'REFERRAL_NOTE', bgColor: '#f50057' },
        { icon: <NotesIcon />, name: 'Sick Note', type: 'SICK_NOTE', bgColor: '#d500f9' },
        { icon: <LocalPharmacyIcon />, name: 'Script', type: 'SCRIPT', bgColor: '#ffc400' },
    ];

    const closeDialog = () => {
        setOpenClinicalDocument(false);
    };

    const handleOpenDocument = (rowData) => {
        getReportDocument(rowData.file_location)
        .then((res) => {
            if(!res){
                enqueueSnackbar("Upload does not exist", { variant: 'error' });
                return;
            }
            setFilePresignedURL(res);
            setDocumentTitle(rowData.document_title);
            handleOpenPDF();
        })
        .catch((err) => {
            enqueueSnackbar("Failed to fetch upload", { variant: 'error' })
        });
    };

    const columns = [
        {
            field: 'document_title',
            title: 'TITLE',
            render: (rowData) => <TitleTypeDocumentCell cell={rowData} />,
        },
        {
            field: 'created_by',
            title: 'CREATED BY',
        },
        {
            field: 'document_status',
            title: 'STATUS',
            render: (rowData) => (
                <Chip
                    color={
                        rowData.document_status === 'generated'
                            ? 'default'
                            : rowData.document_status === 'error'
                            ? 'secondary'
                            : 'default'
                    }
                    style={{ textTransform: 'capitalize' }}
                    label={rowData.document_status}
                />
            ),
        },
    ];
    return (
        <div style={{ marginTop: '24px' }}>
            <MaterialTable
                data={tableData}
                columns={columns}
                title={config.title ? config.title : 'Generate Clinical Documents'}
                isLoading={tableLoading}
                actions={[
                    {
                        icon: () => (
                            <SpeedDial
                                ariaLabel="Generate Clinical Document"
                                className={classes.speedDial}
                                hidden={hidden}
                                icon={<CreateIcon />}
                                onClose={handleClose}
                                onOpen={handleOpen}
                                open={open}
                            >
                                {actions.map((action) => (
                                    <SpeedDialAction
                                        key={action.name}
                                        icon={action.icon}
                                        tooltipTitle={action.name}
                                        classes={classes}
                                        FabProps={{
                                            style: {
                                                backgroundColor: action.bgColor,
                                                color: 'white'
                                            }
                                        }}
                                        tooltipOpen
                                        TooltipClasses={classes}
                                        onClick={(event) => {
                                            setClinicalDocumentType(action.type);
                                            setOpenClinicalDocument(true);
                                        }}
                                    />
                                ))}
                            </SpeedDial>
                        ),
                        tooltip: 'Create Clinical Document',
                        isFreeAction: true
                    },
                    (rowData) => ({
                        icon: () => (
                            <Button color="primary" variant="contained" style={{ marginRight: 15 }}>
                                {' '}
                                View{' '}
                            </Button>
                        ),
                        tooltip: 'View document',
                        onClick: (event, rowData) => handleOpenDocument(rowData),
                        disabled: rowData.document_status !== 'generated',
                    }),
                ]}
                options={{
                    headerStyle: {
                        color: '#999999',
                        fontSize: '11px',
                        fontWeight: 600,
                        lineHeight: '15px',
                    },
                    search: false,
                    actionsColumnIndex: -1,
                }}
            />
            <GenerateDocumentDialog
                open={openClinicalDocument}
                closeDialog={closeDialog}
                handleSubmitClinicalDocument={handleSubmitClinicalDocument}
                clinicalDocumentType={clinicalDocumentType}
                handleCloseReferralNote={handleCloseReferralNote}
            />
            <DocumentViewerDialog
                title={documentTitle}
                open={openPdfViewer}
                isUrl={true}
                data={filePresignedURL}
                setClose={setClosePdfViewer}
            />
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    speedDial: {
        position: 'absolute',
        bottom: theme.spacing(1),
        right: theme.spacing(2),
    },
    fab: {
        // margin: theme.spacing(0.5),
        // marginLeft: theme.spacing(-0.5)
    },
    staticTooltipLabel: {
        width: '100px',
    },

    // actionsContainer: {
    //     display: 'grid',
    //     gridTemplateColumns: 'auto auto',
    //     columnGap: theme.spacing(1),
    //     width: '224px',
    //     marginLeft: 'auto',
    //     marginRight: 'auto'
    // }
}));

export default ClinicalDocumentsTable;
